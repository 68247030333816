import { useState } from "react"
import styled from "styled-components"

import { ThermostatTemperatureInput } from "src/components/Integrations/IntegrationConnectDialogs/ThermostatTemperatureInput"
import { TThermostatMode } from "src/data/homes/types/homeTypes"
import { translateThermostatMode } from "src/data/integrations/logic/integrations"
import {
  TAvailableThermostatMode,
  TThermostatSettings,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchHome } from "src/data/organizations/queries/homeQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getTemperatureUnitWithFallback } from "src/data/user/logic/userTemperature"
import { TTemperatureUnit } from "src/data/user/user"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MDivider } from "src/ui/Divider/Divider"
import { MSelect, TMSelectOptions } from "src/ui/MSelect/MSelect"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"
import { convertTemperatureValue, toCelsius } from "src/utils/l10n"

export type TSettings = {
  heatTemperature: number
  coolTemperature: number
  preHeatCoolTime: number
}

const HEAT_TEMP_DEFAULT = 20 // in celsius
const COOL_TEMP_DEFAULT = 24 // in celsius
const MAX_PRE_HEAT_COOL_TIME = 720 // in minutes
const MIN_PRE_HEAT_COOL_TIME = 60 // in minutes
const DEFAULT_PRE_HEAT_COOL_TIME = 180 // in minutes

export function ThermostatSettingsDialog({
  open,
  onClose,
  thermostatSettings,
  homeId,
}: {
  open: boolean
  onClose: () => void
  thermostatSettings: TThermostatSettings
  homeId: string
}) {
  const { t } = useTranslate()

  const { orgId } = useOrganization()
  const user = useGetUser()
  const temperatureUnit = getTemperatureUnitWithFallback(user)

  const [selectedMode, setSelectedMode] = useState<TThermostatMode | undefined>(
    thermostatSettings.mode
  )

  const [temperatureControlValues, setTemperatureControlValues] = useState({
    heatTemperature: Number(
      convertTemperatureValue(
        thermostatSettings.setPoints?.heat_temperature || HEAT_TEMP_DEFAULT,
        temperatureUnit,
        0
      )
    ),
    coolTemperature: Number(
      convertTemperatureValue(
        thermostatSettings.setPoints?.cool_temperature || COOL_TEMP_DEFAULT,
        temperatureUnit,
        0
      )
    ),
    preHeatCoolTime:
      (thermostatSettings.turnOnTime || DEFAULT_PRE_HEAT_COOL_TIME) / 60,
  })

  const patchHome = usePatchHome()

  function handleSave() {
    const { heatTemperature, coolTemperature, preHeatCoolTime } =
      temperatureControlValues

    const heatTemperatureCelsius =
      temperatureUnit === "F"
        ? toCelsius({ value: heatTemperature, decimals: 0 })
        : heatTemperature

    const coolTemperatureCelsius =
      temperatureUnit === "F"
        ? toCelsius({ value: coolTemperature, decimals: 0 })
        : coolTemperature

    !!homeId &&
      patchHome.mutate(
        {
          orgId,
          homeId,
          data: {
            thermostat_temperature_setpoint_on_checkin: {
              heat_temperature: Number(heatTemperatureCelsius),
              cool_temperature: Number(coolTemperatureCelsius),
            },
            thermostat_turn_on_in_minutes_before_checkin: preHeatCoolTime * 60,
            thermostat_mode_on_checkin: selectedMode,
          },
        },
        {
          onSuccess: () => onClose(),
        }
      )
  }

  return (
    <MDialog
      title={t(langKeys.integrations_thermostat_settings_dialog_title)}
      description={t(
        langKeys.integrations_thermostat_settings_dialog_description
      )}
      open={open}
      onClose={() => {
        patchHome.reset()
        onClose()
      }}
      confirmLabel={t(langKeys.save)}
      onConfirm={handleSave}
      loading={patchHome.isLoading}
      error={
        patchHome.isError
          ? t(langKeys.failed_general_error_try_refreshing_page)
          : undefined
      }
    >
      <ThermostatSettings
        temperatureControlValues={temperatureControlValues}
        setTemperatureControlValues={setTemperatureControlValues}
        temperatureUnit={temperatureUnit}
        selectedMode={selectedMode}
        setSelectedMode={setSelectedMode}
        commonAvailableNestModes={thermostatSettings.commonAvailableNestModes}
      />
    </MDialog>
  )
}

function ThermostatSettings({
  temperatureControlValues,
  setTemperatureControlValues,
  temperatureUnit,
  selectedMode,
  setSelectedMode,
  commonAvailableNestModes,
}: {
  temperatureControlValues: TSettings
  setTemperatureControlValues: (value: TSettings) => void
  temperatureUnit: NonNullable<TTemperatureUnit>
  selectedMode?: TThermostatMode
  setSelectedMode: (value?: TThermostatMode) => void
  commonAvailableNestModes?: TAvailableThermostatMode[]
}) {
  const { t } = useTranslate()

  const temperatureDelta = temperatureUnit === "C" ? 3 : 6

  const options: TMSelectOptions =
    commonAvailableNestModes?.map((mode) => ({
      label: translateThermostatMode(mode, t),
      value: mode,
    })) || []

  return (
    <div>
      <SubSectionBox>
        <div>
          <MText variant="subtitle">
            {t(langKeys.integrations_thermostat_mode_title)}
          </MText>
          <MText color="secondary">
            {t(langKeys.integrations_thermostat_mode_description)}
          </MText>
        </div>

        <MSelect
          label={t(langKeys.integrations_thermostat_mode_title)}
          options={options}
          value={selectedMode || ""}
          onChange={(value) => setSelectedMode(value as TThermostatMode)}
        />
      </SubSectionBox>

      <StyledDivider />

      <SubSectionBox>
        <div>
          <MText variant="subtitle">{t(langKeys.temperature)}</MText>
          <MText color="secondary">
            {t(langKeys.integrations_thermostat_temperature_description)}{" "}
            {selectedMode === "heatcool" &&
              t(
                langKeys.integrations_thermostat_settings_temperature_delta_error,
                { delta: temperatureDelta, unit: temperatureUnit }
              )}
          </MText>
        </div>

        <ThermostatTemperatureInput
          selectedMode={selectedMode}
          temperatureControlValues={temperatureControlValues}
          setTemperatureControlValues={setTemperatureControlValues}
          temperatureUnit={temperatureUnit}
          temperatureDelta={temperatureDelta}
        />
      </SubSectionBox>

      <StyledDivider />

      <SubSectionBox>
        <div>
          <MText variant="subtitle">
            {t(langKeys.integrations_thermostat_settings_preheat_cool_time)}
          </MText>
          <MText color="secondary">
            {t(langKeys.integrations_thermostat_precool_heat_description)}
          </MText>
        </div>

        <MTextField
          type="number"
          min={MIN_PRE_HEAT_COOL_TIME / 60}
          max={MAX_PRE_HEAT_COOL_TIME / 60}
          label={`${t(langKeys.integrations_thermostat_settings_preheat_cool_time)} (${t(langKeys.hours).toLowerCase()})`}
          value={temperatureControlValues.preHeatCoolTime.toString()}
          onChange={(value) =>
            setTemperatureControlValues({
              ...temperatureControlValues,
              preHeatCoolTime: Number(value),
            })
          }
        />
      </SubSectionBox>
    </div>
  )
}

const StyledDivider = styled(MDivider)`
  margin: ${spacing.L} 0;
`

const SubSectionBox = styled.div`
  display: grid;
  gap: ${spacing.L};
`
