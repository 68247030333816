import { MenuItem, MenuList } from "@material-ui/core"

import { translateThermostatMode } from "src/data/integrations/logic/integrations"
import { TAvailableThermostatMode } from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchHome } from "src/data/organizations/queries/homeQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { DropdownButton } from "src/ui/Button/DropdownButton"
import { MText } from "src/ui/MText"

export function SelectNestThermostatMode({
  commonAvailableNestModes,
  homeId,
  defaultNestMode,
  linked,
}: {
  commonAvailableNestModes: TAvailableThermostatMode[]
  homeId: string
  defaultNestMode: TAvailableThermostatMode | undefined
  linked: boolean
}) {
  const { t } = useTranslate()
  const { orgId } = useOrganization()

  const patchHome = usePatchHome()

  const displayValue = defaultNestMode
    ? translateThermostatMode(defaultNestMode, t)
    : t(langKeys.none)

  if (!commonAvailableNestModes || !linked) {
    return <div></div>
  }

  function handleOnClick(mode: TAvailableThermostatMode) {
    patchHome.mutate({
      orgId,
      homeId,
      data: { thermostat_mode_on_checkin: mode },
    })
  }

  return (
    <div>
      <DropdownButton
        displayValue={<MText>{displayValue}</MText>}
        variant="minimal"
      >
        {({ closeMenu }) => (
          <MenuList>
            {commonAvailableNestModes.map((mode) => (
              <MenuItem
                key={mode}
                onClick={() => {
                  handleOnClick(mode)
                  closeMenu()
                }}
              >
                {translateThermostatMode(mode, t)}
              </MenuItem>
            ))}
          </MenuList>
        )}
      </DropdownButton>
    </div>
  )
}
